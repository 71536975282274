<template>
  <div class="pop-box">
    <div class="search-title">全部筛选</div>
    <div
      v-for="(item,index) in searchData"
      :key="index"
      class="item-box"
    >
      <div class="title">{{item.title}}</div>
      <div class="item">
        <div
          v-for="(child,childIndex) in item.list"
          :key="childIndex"
          class="item-label"
          :class="{'active':child.isActive}"
          @click="clickItem(item.value,child)"
        >
          <div>{{child.label}}</div>
        </div>
      </div>
    </div>
    <div style="position:absolute;bottom:100px;width:100%">
      <van-button
        round
        type="info"
        size="normal"
        style="padding:6px 0;width:80%;"
        @click="searchFun"
      >查询账单</van-button>
    </div>
  </div>
</template>

<script>

export default {
  data () {
    return {
      currHt: '',
      currZd: '',
      searchData: [
        {
          title: '合同类型',
          value: 'htlx',
          list: [
            {
              label: '销售',
              value: 'gzh_xsht',
              isActive: false
            },
            {
              label: '安装',
              value: 'gzh_azht',
              isActive: false
            },
            {
              label: '修缮',
              value: 'gzh_xiusht',
              isActive: false
            },
            {
              label: '维保',
              value: 'gzh_wbht',
              isActive: false
            }
          ]
        },
        {
          title: '费用类型',
          value: 'zdlx',
          list: []
        }
      ]
    }
  },
  props: {
    searchObj: {
      type: Object,
      default: () => {
        return {}
      }
    },
  },
  computed: {
  },
  watch: {
    searchObj: {
      handler (val) {
        this.currHt = val.htlx
        this.currZd = val.zdlx
        // let keys = Object.keys(val).filter(item => { return item.indexOf('_text') == -1 })
        // for (let key of keys) {
        //   this.searchData = this.searchData.map(item => {
        //     if (item.value == key) {
        //       item.list = item.list.map(child => {
        //         if (child.value == val[key]) {
        //           child.isActive = true
        //         } else {
        //           child.isActive = false
        //         }
        //         return child
        //       })
        //     }
        //     return item
        //   })
        // }
      },
      deep: true,
      immediate: true
    },
    currHt: {
      handler (val) {
        if (val == 'gzh_xsht') {
          this.searchData[1].list = [{
            label: '收款计划',
            value: '1',
            isActive: false
          },
          {
            label: '支付款项',
            value: '2',
            isActive: false
          },
          {
            label: '非采购支出',
            value: '4',
            isActive: false
          },
          {
            label: '保证金',
            value: '5',
            isActive: false
          }]
        } else if (val == 'gzh_azht') {
          this.searchData[1].list = [{
            label: '工程票据',
            value: '1',
            isActive: false
          },
          {
            label: '工程报价',
            value: '2',
            isActive: false
          },
          {
            label: '保证金',
            value: '4',
            isActive: false
          },
          {
            label: '其他支出',
            value: '5',
            isActive: false
          }]
        } else if (val == 'gzh_xiusht') {
          this.searchData[1].list = [{
            label: '收款计划',
            value: '1',
            isActive: false
          },
          {
            label: '报价单',
            value: '2',
            isActive: false
          },
          {
            label: '保证金',
            value: '4',
            isActive: false
          },
          {
            label: '其他支出',
            value: '5',
            isActive: false
          },
          {
            label: '支付款项',
            value: '6',
            isActive: false
          }]
        } else if (val == 'gzh_wbht') {
          this.searchData[1].list = [{
            label: '收款票据',
            value: '1',
            isActive: false
          },
          {
            label: '整改报价',
            value: '2',
            isActive: false
          },
          {
            label: '保证金',
            value: '4',
            isActive: false
          },
          {
            label: '报价业务',
            value: '5',
            isActive: false
          },
          {
            label: '年检费用',
            value: '6',
            isActive: false
          }]
        }
        this.searchData[0].list = this.searchData[0].list.map(item => {
          if (item.value == this.currHt) {
            item.isActive = true
          } else {
            item.isActive = false
          }
          return item
        })
        this.searchData[1].list = this.searchData[1].list.map(item => {
          if (item.value == this.currZd) {
            item.isActive = true
          } else {
            item.isActive = false
          }
          return item
        })
      },
      immediate: true
    },
    currZd: {
      handler (val) {
        this.searchData[1].list = this.searchData[1].list.map(item => {
          if (item.value == this.currZd) {
            item.isActive = true
          } else {
            item.isActive = false
          }
          return item
        })
      },
      immediate: true
    }
  },
  mounted () {
  },
  methods: {
    clickItem (type, data) {
      if (type == 'htlx') {
        this.currHt = data.value
      } else if (type == 'zdlx') {
        this.currZd = data.value
      }
    },
    searchFun () {
      //默认查询参数修改
      this.searchData.forEach(item => {
        item.list.forEach(child => {
          if (child.isActive) {
            this.searchObj[item.value] = child.value
            this.searchObj[`${item.value}_text`] = child.label
          }
        })
      })
      this.$emit('searchFun', this.searchObj)
    }
  },
}
</script>
 
<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}
.pop-box {
  position: relative;
  font-size: 14px;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  .search-title {
    font-size: 16px;
    height: 47px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .item-box {
    width: 100%;
    box-sizing: border-box;
    padding: 8px;
    text-align: left;
    .title {
      padding-bottom: 6px;
      // font-weight: bold;
    }
    .item {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 6px;
      .item-label {
        padding: 8px 18px;
        background-color: #f6f6f6;
        margin: 6px;
        border-radius: 14.5px;
        border: 1px solid #fff;
        font-size: 13px;
        box-sizing: border-box;
      }
    }
  }
}
.active {
  background-color: #ecf5ff !important;
  border: 1px solid #1989fa !important;
}
</style>