<template>
  <div class="contentBQ top-padding">
    <top-home title="账单查询"></top-home>
    <van-tabs
      v-model="active"
      color="rgb(41, 167, 254)"
      title-active-color="rgb(41, 167, 254)"
    >
      <van-tab title="账单查询">
        <div class="tapOne">
          <div class="left-box">
            <div class="tapOne-div">
              <div
                class="time"
                @click.capture="showPopupFun('year')"
              >
                <div>{{ yearText }}年</div>
                <img src="../../../assets/CostManager/sjx.png" />
              </div>
            </div>
            <div class="tapOne-div">
              <div
                class="time"
                @click.capture="showPopupFun('bill')"
              >
                <div>{{ billText }}</div>
                <img src="../../../assets/CostManager/sjx.png" />
              </div>
            </div>
            <div>
              <van-button
                type="info"
                size="20px"
                @click="queryBillFun"
              >查询</van-button>
            </div>
          </div>
          <div
            class="sel-box"
            @click="openSearchPop"
          >
            <span>筛选</span>
            <van-icon
              name="filter-o"
              size="20"
            />
          </div>
        </div>
        <div class="search-box">
          <div>筛选条件：{{defaultSearch.htlx_text}} - {{defaultSearch.zdlx_text}}</div>
          <div class="totalFee">总金额：¥ {{billTotalFee}}</div>
        </div>
        <div class="bill-list-box">
          <van-list
            v-model="listLoading"
            :finished="true"
            :finished-text="billTip"
          >
            <div
              class="list-item"
              v-for="(item,index) in billList"
              :key="index"
              @click="opentBillDetailsFun(item)"
            >
              <div class="item-content">
                <div class="content-lie">
                  <div class="lie yf">{{item.title}}</div>
                  <div class="je">¥ {{item.zdje}}</div>
                </div>
                <div class="content-lie">
                  <div class="lie sj">
                    <span>时间:</span>
                    {{item.zdrq}}
                  </div>
                  <div class="zt">
                    <span>状态:</span>
                    <span :class="[{'error':item.sfxz=='0'},{'success':item.sfxz=='1'}]">
                      {{item.sfxz=='0'?'未销账':'已销账'}}
                    </span>
                  </div>
                </div>
                <div class="content-lie">
                  <div class="lie">
                    <span>编号 | 名称:</span>
                    {{item.htbh}} | {{item.xmmc}}
                  </div>
                </div>
              </div>
            </div>
          </van-list>
        </div>
      </van-tab>
      <van-tab title="缴费查询">
        <div class="tapOne">
          <div class="left-box">
            <div class="tapOne-div">
              <div
                class="time"
                @click.capture="showPopupFun('payYear')"
              >
                <div>{{ payYearText }}年</div>
                <img src="../../../assets/CostManager/sjx.png" />
              </div>
            </div>
            <div>
              <van-button
                type="info"
                size="20px"
                @click="queryPayFun"
              >查询</van-button>
            </div>
          </div>
          <div class="totalFee">总金额：¥ {{payTotalFee}}</div>
        </div>
        <div class="bill-list-box">
          <van-list
            v-model="listLoading"
            :finished="true"
            :finished-text="payTip"
          >
            <div
              class="list-item"
              v-for="(item,index) in payList"
              :key="index"
              @click="opentPayDetailsFun(item.id)"
            >
              <div class="item-content">
                <div class="content-lie">
                  <div class="lie yf">{{item.title}}</div>
                  <div class="je">¥ {{item.ssje}}</div>
                </div>
                <div class="content-lie">
                  <div class="lie sj">
                    <span>支付时间:</span>
                    {{item.jfsj}}
                  </div>
                  <div class="zt">
                    <span>{{item.jffs}}</span>
                  </div>
                </div>
                <div
                  class="content-lie"
                  v-if="item.subbill=='1'"
                >
                  <div class="lie">
                    <span>编号 | 名称:</span>
                    {{item.htbh}} | {{item.xmmc}}
                  </div>
                </div>
                <div class="content-lie">
                  <div class="lie">
                    <span>{{item.lx}}</span>
                  </div>
                </div>
              </div>
            </div>
          </van-list>
        </div>
      </van-tab>
      <van-tab title="我的发票">
        <MyInvoice />
      </van-tab>
    </van-tabs>
    <van-popup
      v-model="isPopup"
      position="bottom"
      :style="{ height: '50%' }"
    >
      <van-picker
        ref="picker"
        :title="pickerTitle"
        show-toolbar
        :columns="columns"
        :default-index="pickerIndex"
        @confirm="pickerConfirmFun"
        @cancel="isPopup=false"
      />
    </van-popup>
    <van-popup
      class="popup-details"
      v-model="isDetails"
      position="right"
      :style="{ width:'100%' }"
    >
      <BqDetails
        :searchObj="defaultSearch"
        :currZdData="currZdData"
      ></BqDetails>
      <div
        class="details-cross"
        @click="isDetails=!isDetails"
      >
        <van-icon name="cross" />
      </div>
    </van-popup>
    <van-popup
      class="popup-details"
      v-model="isPayDetails"
      position="right"
      :style="{ width:'100%' }"
    >
      <PayDetails :sfjlId="paySfjlId"></PayDetails>
      <div
        class="details-cross"
        @click="isPayDetails=!isPayDetails"
      >
        <van-icon name="cross" />
      </div>
    </van-popup>
    <van-popup
      class="popup-details"
      v-model="isSearch"
      position="right"
      :style="{ width:'100%' }"
    >
      <SearchPop
        v-if="isSearch"
        @searchFun="searchFun"
        :searchObj="defaultSearch"
      ></SearchPop>
      <div
        class="details-cross"
        @click="isSearch=!isSearch"
      >
        <van-icon name="cross" />
      </div>
    </van-popup>
  </div>
</template>

<script>
import { getBillListApi, getPayListApi } from '@/reuqest/api.js'
import BqDetails from '@/components/CostManager/BillQuery/BqDetails.vue'
import SearchPop from '@/components/CostManager/BillQuery/SearchPop.vue'
import PayDetails from '@/components/CostManager/BillQuery/PayDetails.vue'
import MyInvoice from '@/components/CostManager/BillQuery/MyInvoice.vue'

export default {
  components: {
    BqDetails,
    PayDetails,
    MyInvoice,
    SearchPop
  },
  data () {
    return {
      active: '',
      isDetails: false,
      isPayDetails: false,
      paySfjlId: '',
      listLoading: false,

      year: [],
      yearText: new Date().getFullYear(),

      payYear: [],
      payYearText: new Date().getFullYear(),
      payList: [],
      payTotalFee: 0,
      payTip: '没有更多了',

      bill: ['全部', '已销账', '未销账'],
      billText: '全部',
      billList: [],
      billTotalFee: 0,
      billTip: '没有更多了',

      pickerTitle: '',
      columns: [],
      isPopup: false,
      pickerIndex: 0,
      pickerType: '',
      isSearch: false,
      defaultSearch: {
        htlx: 'gzh_xsht',
        htlx_text: '销售',
        zdlx: '1',
        zdlx_text: '收款计划'
      },
      currZdData: {}
    }
  },
  filters: {},
  created () {
    let minYear = 2018
    let currYear = new Date().getFullYear()
    let num = currYear - 0 + 1
    for (let index = minYear; index < num; index++) {
      this.year.push(index)
    }
  },
  mounted () {
    this.queryBillFun()
    this.queryPayFun()
  },
  methods: {
    //显示弹窗
    showPopupFun (type) {
      this.pickerType = type
      if (type == 'year' || type == 'payYear') {
        this.columns = this.year
        this.pickerTitle = '年份'
      } else if (type == 'bill') {
        this.columns = this.bill
        this.pickerTitle = '状态'
      }
      this.columns.forEach((item, index) => {
        if (item == this[type + 'Text']) {
          this.pickerIndex = index
        }
      })
      this.isPopup = true
    },
    //确认
    pickerConfirmFun () {
      let value = this.$refs.picker.getValues()[0]
      this[this.pickerType + 'Text'] = value
      this.isPopup = false
    },
    //获取账单list
    queryBillFun (params) {
      this.isSearch = false
      const loadingToast = this.$toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 0,
      })
      let obj = {
        years: this.yearText,
      }
      if (this.billText == '已销账') {
        obj.billzt = '1'
      } else if (this.billText == '未销账') {
        obj.billzt = '0'
      }
      if (params && Object.keys(params).length > 0) {
        this.defaultSearch = {
          ...this.defaultSearch,
          ...params
        }
      }
      obj = {
        ...obj,
        ...this.defaultSearch
      }
      getBillListApi(obj).then((res) => {
        this.billList = res.data.dataList || []
        this.billTotalFee = res.data.totalFee || 0
        if (this.billList.length > 0) {
          this.billTip = '没有更多了~'
        } else {
          this.billTip = '暂无相关数据~'
        }
        loadingToast.clear()
      })
    },
    //获取缴费list
    queryPayFun (params) {
      this.isSearch = false
      const loadingToast = this.$toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 0,
      })
      let obj = {
        years: this.payYearText,
      }
      if (params && Object.keys(params).length > 0) {
        this.defaultSearch = {
          ...this.defaultSearch,
          ...params
        }
      }
      obj = {
        ...obj,
        ...this.defaultSearch
      }
      getPayListApi(obj).then((res) => {
        this.payList = res.data.zdList
        this.payTotalFee = res.data.zdhj
        if (this.payList.length > 0) {
          this.payTip = '没有更多了~'
        } else {
          this.payTip = '暂无相关数据~'
        }
        loadingToast.clear()
      })
    },
    searchFun (params) {
      this.queryBillFun(params)
    },
    opentBillDetailsFun (data) {
      this.isDetails = true
      setTimeout(() => {
        this.currZdData = data
      }, 330)
    },
    opentPayDetailsFun (id) {
      this.isPayDetails = true
      setTimeout(() => {
        this.paySfjlId = id
      }, 330)
    },
    jumpMayInvoice () {
      this.$router.push('/MyInvoice')
    },
    openSearchPop () {
      this.isSearch = true
    },
  },
}
</script>
 
<style lang="less" scoped>
.contentBQ {
  height: 100vh;
  background-color: rgb(245, 245, 245);
}
.both {
  display: flex;
}
.time {
  background-color: #ccc;
}
.totalFee {
  flex: 1;
  text-align: right;
  color: #21a4fe;
  font-size: 14px;
}
.tapOne {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
  .left-box {
    display: flex;
    align-items: center;
  }
  .tapOne-div {
    .time {
      border-radius: 3px;
      border: 1px solid rgb(245, 245, 245);
      background-color: #fff;
      margin-right: 5px;
      height: 25px;
      padding: 3px 6px;
      display: flex;
      align-items: center;
      div {
        padding-right: 4px;
      }
      img {
        width: 10px;
      }
    }
  }
}
.van-button {
  height: 100%;
  padding: 5px 10px;
}
.poppicker {
  display: flex;
  justify-content: space-between;
  position: relative;
  background-color: #fff;
  border-bottom: 5px soild rgb(245, 245, 245);
  .poppicker-one {
    padding: 10px;
  }
  .poppicker-two {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    span {
      font-size: 14px;
      width: 49%;
      margin-left: 3px;
    }
  }
}
.bill-list-box {
  background-color: #f5f5f5;
  .list-item {
    background-color: #fff;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    padding: 5px 0;
    .item-img {
      margin: 0 10px;
      img {
        width: 45px;
      }
    }
    .item-content {
      width: 100%;
      padding: 8px;
      .je {
        color: #ffb54c;
        line-height: 22px;
        width: 100px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      .content-lie {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        padding: 3px 0;
        color: #232323;
        text-align: left;
        .lie {
          flex: 1;
        }
        .zt {
          width: 100px;
        }
        span {
          color: #999;
        }
      }
    }
  }
}
.popup-details {
  .details-cross {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 20px;
    height: 47px;
    width: 47px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.search-box {
  display: flex;
  align-items: center;
  padding: 0 11px;
  font-size: 14px;
  color: #999;
  padding-bottom: 3px;
}
.sel-box {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #21a4fe;
}
.ellipsis {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>